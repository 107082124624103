import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import images from '../../constant/images';
import {
    Building,
    Formkit,
    Reports,
    Users,
    LogOut,
    Home
} from '../../constant/icons'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout, resetUserState } from "../../slices/authSlice";
import { useDispatch, useSelector } from 'react-redux';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { toast } from 'react-toastify';
import { useLogoutQuery } from "../../slices/getSlice";
import { useLogoutMutation } from "../../slices/apiSlice";
import AuditLog from "../../pages/AuditLog/index.js";
import { AiTwotoneBook } from "react-icons/ai";
import { AiOutlineAudit } from "react-icons/ai";
import { GiExpense } from "react-icons/gi";
import { FaRegFilePdf } from "react-icons/fa";
import { GrAnalytics, GrTransaction } from "react-icons/gr";

const Sidebar = () => {
    const location = useLocation()
    const [selectedKey, setSelectedKey] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Logo } = images;
    const status = useSelector((state) => state.persistedReducer.status);
    const userData = useSelector((state) => state.persistedReducer);
    const parent_id = userData?.user?.user?.parent_id;
  
   const [logoutUser] = useLogoutMutation()
   const handleLogout = () => {
    dispatch(logout());
//    await logoutUser(); 
    localStorage.clear();
    navigate("/login");
    toast.success('Logout successfully');
};


    useEffect(() => {
        const path = location.pathname;
        let key = path;

        if (path === '/view_property' || path === '/add_property' || path === '/property_details') {
            key = '/properties';
        } else if (path === '/add_income') {
            key = '/income';
        }

        setSelectedKey(key);
    }, [location.pathname]);


    const items = [
        {
            key: '/dashboard',
            icon: <Home className="menu-icon" />,
            disabled: !status,
            path: '/dashboard',
            label: status ? <Link className="opacity" to={'/dashboard'}>{'Dashboard'}</Link> : <span>{'Dashboard'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/properties',
            icon: <Building className="menu-icon" />,
            path: '/properties',
            disabled: !status,
            label: status ? <Link to={'/properties'}>{'Properties'}</Link> : <span>{'Properties'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/manage_property',
            icon: <Formkit className="menu-icon" />,
            path: '/manage_property',
            hidden:parent_id?true:false,
            disabled: !status,
            label: status ? <Link to={'/manage_property'}>{'Groups'}</Link> : <span>{'Groups'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/income',
            icon: <RiMoneyDollarCircleLine className="menu-icon" />,
            path: '/income',
            disabled: !status,
            label: status ? <Link to={'/income'}>{'Income'}</Link> : <span>{'Income'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/sub_account',
            icon: <Users className="menu-icon" />,
            path: '/sub_account',
            hidden:parent_id?true:false,
            disabled: !status,
            label: status ? <Link to={'/sub_account'}>{'Sub Accounts'}</Link> : <span>{'Sub Accounts'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/report',
            icon: <Reports className="menu-icon" />,
            path: '/report',
            hidden:parent_id?true:false,
            disabled: !status,
            label: status ? <Link to={'/report'}>{'Overall Performance'}</Link> : <span>{'Overall Performance'}</span>,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: '/audit-log',
            icon: <AiOutlineAudit className="menu-icon" />,
            path: '/audit-log',
            hidden:parent_id?true:false,
            disabled: !status,
            label: status ? <Link to={'/audit-log'}>{'Audit Log'}</Link> : <span>{'Audit Log'}</span>,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: '/all-transcation',
            icon:<GrTransaction className="menu-icon"/>,
            path: '/all-transcation',
            hidden:parent_id?true:false,
            disabled: !status,
            label: status ? <Link to={'/all-transcation'}>{'Transcation'}</Link> : <span>{'Transcation'}</span>,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: 9,
            icon: <LogOut className="menu-icon" />,
            label: 'Logout',
            style: { marginTop: '44px' },
            className: 'custom-menu-item bottem-menu-item',
            onClick: handleLogout
        },
    ]

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            style={{ background: '#3A54A5' }}
            width="20%"
            className="mobWidth"
        >
            <div className="logoContainer" >
                <Logo onClick={() => {
                    navigate('/dashboard')
                }} />
            </div>
            <Menu mode="inline" style={{ background: '#3A54A5', marginTop: '2rem' }} items={items} selectedKeys={[selectedKey]} >
            </Menu>
        </Sider>
    )
}
export default Sidebar;