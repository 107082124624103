import React from 'react';
const Title = ({ title ,subtitle }) => {
    return (
        <div className='titleWrapper'>
            <h2 className='cardTitle'>{title}</h2>
            <p className={ subtitle ? 'subtitle' : 'hide'} >{subtitle?subtitle:''}</p>
        </div>
    )
}

export default Title;
