
import React, { useState } from "react";
import { Form } from "antd";
import DynamicForm from "../../component/Form";
import { CiLock } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import images from '../../constant/images';
import { useResetPasswordMutation } from "../../slices/loginRegisterSlice";


const ResetPassword = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const email = useSelector((state) => state?.persistedReducer?.email?.email?.email)
    // const dispatch = useDispatch();
    const [form] = Form.useForm();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');
    const { Logo, SignInImage } = images; 
    const fields = [
        {
            component: 'input',
            type: 'password',
            label:'Password',
            name: 'password',
            placeholder: 'Enter password',
            prefix: <CiLock
                style={{ color: '#F47939', fontSize: '25px' }} />,
            rules: [
                {
                    required: true,
                    message: 'Please input your password',
                },
                {
                    pattern: /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    message: 'Password must be minimum 8 characters, include at least one uppercase letter, one lowercase letter, one number, and one special character.',
                  }
                  ,
            ],

        },
        {
            component: 'input',
            type: 'password',
            dependencies: 'password',
            label:'Confirm Password',
            name: 'password_confirmation',
            placeholder: 'Enter Confirm password',
            prefix: <CiLock
                style={{ color: '#F47939', fontSize: '25px' }} />,
            rules: [
                {
                    required: true,
                    message: 'Please input your password',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The confirm passwords do not match!'));
                    },
                })
             
            ],

        },
          
       
    ];

     const [userResetPassword, { isLoading}] = useResetPasswordMutation();

    const onFinish = async (value) => {
        let data ={
            email:email,
            token:token,
            ...value
        }
        try {
            const response = await userResetPassword(data);
            if (response.data) {
                toast.success(`${response.data.message}`)
                navigate("/login");                 
            }else{
                setErrorMessage(response.error.data.message);
            }
        } catch (error) {
             console.log(error)
        }
        setTimeout(() => {
            setErrorMessage("");
          }, 5000);
    };

    return (
        <div className="authWrapper">
            <div className="authInner">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="col-md-6 whiteBg">
                            <div className="formWrapper">
                                <div className="logoWrap">
                                    <Logo />
                                </div>
                                <div className="titleWrap">
                                    <h2 className="authTitle">Reset Password</h2>
                                  
                                </div>
                                <div className="formWrap">
                                {errorMessage && (
                    <div style={{ color: "red" }}>{errorMessage}</div>
                  )}
                 
                                    <DynamicForm 
                                        form={form} 
                                        fields={fields} 
                                        onFinish={onFinish} 
                                        buttonName={'Save'} 
                                         loading={isLoading} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mobHide">
                            <div className="imgWrapper">
                                <SignInImage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
