import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: null,
  email:null,
  currency:null,
  status:'',
  detail:null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    registerSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    saveEmail: (state, action) => {
      state.email = action.payload;
    },
    saveCurrency:(state,action)=>{   
      state.currency =  action.payload
    },
    userStatus:(state,action)=>{
       state.status = action.payload
    },
    userDetail : (state,action)=>{
        state.detail = action.payload
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
    resetUserState: () => initialState,
  
  },
});

export const { loginSuccess, logout, registerSuccess, saveEmail, saveCurrency, userStatus, userDetail, resetUserState } = authSlice.actions;
export default authSlice.reducer;
