import { Button, Pagination, Select, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import Title from "../../component/PageTitle";
import {
  useGetCategoryQuery,
  useGetUtilityCategoryQuery,
} from "../../slices/getSlice";
import {
  useMonthlyExpenseMutation,
  useMonthlyIncomeMutation,
} from "../../slices/apiSlice";
import { useSelector } from "react-redux";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function AuditLog() {
  const pageSize = 5;
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  const currencyGlobal = useSelector(
    (state) => state?.persistedReducer?.currency?.currency_symbol
  );
  const currency = currencyGlobal ? currencyGlobal : "";
  const [selectedValue, setSelectedValue] = useState("Income");
  const[activeButton,setActiveButton] = useState(0)
  const [data, setData] = useState();
  const { data: getUtilityCategory = [] } = useGetUtilityCategoryQuery();
  const { data: getCategory, error } = useGetCategoryQuery('income');
  const [getMonthlyIncome, {isLoading: isIncomeLoading} ] = useMonthlyIncomeMutation();
  const [getMonthlyExpense, {isLoading: isExpenseLoading} ] = useMonthlyExpenseMutation();

  const isLoading = isIncomeLoading || isExpenseLoading;
  const Columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Month",
      dataIndex: "month",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Amount",
      dataIndex: "total_amount",
      render: (total_amount) => `${currency}${total_amount}`,
    },
  ];
  useEffect(() => {
    handleSelectIncome(getCategory?.data[0]?.id);
    if(error?.status == 401){
      localStorage.clear();
      window.location.reload()
      navigate("/login");
    }
  }, [getCategory?.data[0]?.id]);
  
  const handleSelectIncome = async (value) => {
   
    let data = {
      category_id: value ,
    };
    const res = await getMonthlyIncome(data);
    setData(res?.data?.filter((monthly) => monthly.total_amount!== '0.00') || []);
  };

  const handleSelectExpanse = async (value) => {
    let data = {
      provider_type_id: value ,
    };
    const res = await getMonthlyExpense(data);
    setData(res?.data?.filter((monthly) => monthly.total_amount !== '0.00') || []);
  };
  const dataSource = data ?? [];

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };
  const totalItems = dataSource ? dataSource.length : 0;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalItems);
  const displayedItems = dataSource.slice(startIndex, endIndex);

  const CustomNextArrow = (props) => {
    const { className, style, onClick, currentSlide, slideCount } = props;
    if (currentSlide === slideCount - 1) {
      return null;
    }
    return (
      <div
        className={`${className} ${
          selectedValue === "Expense"
            ? getUtilityCategory?.data?.length > 4
              ? "custom-arrow custom-next-arrow"
              : ""
            : getCategory?.data?.length > 4
            ? "custom-arrow custom-next-arrow"
            : ""
        }`}
        style={{ ...style, display: "block", right: -25, color: "black" }}
        onClick={onClick}
      >
        <RightOutlined />
      </div>
    );
  };

  const CustomPrevArrow = (props) => {

    const { className, style, onClick, currentSlide } = props;
    if (currentSlide === 0) {
      return null;
    }
    return (
      <div
        className={`${className} ${
          selectedValue === "Expense"
            ? getUtilityCategory?.data?.length > 4
              ? "custom-arrow custom-prev-arrow"
              : ""
            : getCategory?.data?.length > 4
            ? "custom-arrow custom-prev-arrow"
            : ""
        }`}
        style={{ ...style, display: "block",left:-25 }}
        onClick={onClick}
      >
        <LeftOutlined />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
 
  return (
    <>
      <div className="mainWrapper">
        <div className="card titleCard mb-4">
          <div className="card-body d-flex justify-content-between align-items-center">
            <Title title="Audit Log" />
            <Select
              defaultValue={"Select the option"}
              value={selectedValue}
              style={{ width: 150 }}
              onChange={(value) => {
                setSelectedValue(value);
                if (value == "Expense") {
                  setActiveButton(0)
                  handleSelectExpanse(getUtilityCategory?.data[0]?.id);
                } else {
                  setActiveButton(0)
                  handleSelectIncome(getCategory?.data[0]?.id);
                }
              }}
              options={[
                { value: "Income", label: "Income" },
                { value: "Expense", label: "Expense" },
              ]}
            />
          </div>
        </div>
        <div className="card tableCard">
          {selectedValue === "Expense" ? (
            <div className="card-header d-flex align-items-center justify-content-evenly border-0 w-100">
              <div className="button-group-header d-flex align-items-center w-100 justify-content-between audit-log-card">
                <Slider {...settings}>
                  {getUtilityCategory?.data?.map((utility,index) => (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={`btnOutlined ${activeButton === index ? 'active' : ''}`}
                      onClick={() => {setActiveButton(index);handleSelectExpanse(utility.id)}}
                    >
                      {utility.name}
                    </Button>
                  ))}
                </Slider>
              </div>
            </div>
          ) : (
            <div className="button-group-header pt-3 d-flex align-items-center w-100 justify-content-between audit-log-card">
              <Slider {...settings}>
                {getCategory?.data?.map((category,index) => (
                  <Button
                  key={category.id}
                    type="primary"
                    htmlType="submit"
                    className={`btnOutlined ${activeButton === index ? 'active' : ''}`}
                    onClick={() => {setActiveButton(index); handleSelectIncome(category.id)}}
                  >
                    {category.name}
                  </Button>
                ))}
              </Slider>
            </div>
          )}

          <div className="card-body">
          <div className="table-responsives">
             
         
                <Spin spinning={isLoading} size="large">
      <Table
        rowKey={(record) => record.id}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={displayedItems}
        columns={Columns}
        pagination={false}
      />
    </Spin>
                <Pagination
                  className="mt-4"
                  current={currentPage}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={handleChangePage}
                />
             
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuditLog;
