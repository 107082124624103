
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "../pages/auth/login";
import SignUp from "../pages/auth/signUp";
import Profile from "../pages/User/Profile";
import AddProperty from "../pages/properties/AddProperty";
import Layout from "../component/Layout";
import AllProperties from "../pages/properties/AllProperties";
import ViewProperty from "../pages/properties/ViewProperty";
import CreateSubAccount from "../pages/User/CreateSubAccount";
import PropertyDetails from "../pages/properties/PropertyDetails";
import FinanceProviderDetails from "../pages/Finance/FinanceProviderDetails";
import HouseResources from "../pages/HouseResources/HouseResources";
import ManageProperty from "../pages/Groups/ManageProperty";
import Dashboard from "../pages/Dashboard";
import Income from "../pages/Income/Income";
import AddIncome from "../pages/Income/AddIncome";
import Report from "../pages/Report";
import ForgotPassword from "../pages/auth/forgotPassword";
import ResetPassword from "../pages/auth/resetPassword";
import AllSubAccount from "../pages/User/AllSubAccount";
import SubAccountReset from "../pages/auth/SubAccountReset";
import AuditLog from "../pages/AuditLog";
import ExpenseCategory from "../pages/ExpenseCategory";
import MarketAnalysis from "../pages/MarketAnalysis";
import ReportAnalysis from "../pages/ReportAnalysis";
import AssignProperty from "../pages/User/AssignProperty";
import AllTranscation from "../pages/AllTranscation";

const AppRoute = () => {
  const userData = useSelector((state) => state.persistedReducer);

  const isAuthenticated = userData.isAuthenticated;

  const ProtectedRoute = ({ children, redirectTo }) => {
    const status = useSelector((state) => state.persistedReducer.status);
    
    return  status  ? children : <Navigate to={redirectTo} />;
  };


  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Navigate to={"/dashboard"} replace />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add_property"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AddProperty />
                </ProtectedRoute>
              }
            />
            <Route
              path="/view_property"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <ViewProperty />
                </ProtectedRoute>
              }
            />
            <Route
              path="/properties"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AllProperties />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create_sub_account"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <CreateSubAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path="/property_details"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <PropertyDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage_property"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <ManageProperty />
                </ProtectedRoute>
              }
            />
            <Route
              path="/income"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <Income />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add_income"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AddIncome />
                </ProtectedRoute>
              }
            />
            <Route
              path="/report"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <Report />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sub_account"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AllSubAccount />
                </ProtectedRoute>
              }
            />
              <Route
              path="/audit-log"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AuditLog/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/finance_provider_details"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <FinanceProviderDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/house_resources"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <HouseResources />
                </ProtectedRoute>
              }
            />
            <Route
              path="/expense-category"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <ExpenseCategory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/market-analysis"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <MarketAnalysis />
                </ProtectedRoute>
              }
            />
            <Route
              path="/report-analysis"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <ReportAnalysis />
                </ProtectedRoute>
              }
            />
             <Route
              path="/assign-property"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AssignProperty/>
                </ProtectedRoute>
              }
            />
              <Route
              path="/all-transcation"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AllTranscation/>
                </ProtectedRoute>
              }
            />
          </Route>
        </>
      ) : (
        <>
         
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/reset" element={<SubAccountReset />} />
          <Route path="/*" element={<Navigate to="/login" replace />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoute;
