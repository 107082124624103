import React from 'react';
import { Layout } from 'antd';
import Sidebar from '../Sidebar';
import TopHeader from '../Header';
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

const App = () => {
    return (
        <Layout
            style={{
                height: '100vh'
            }}
        >
            <Sidebar />
            <Layout>
                <TopHeader />
                <Content
                    className='layoutContent'
                >
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};
export default App;