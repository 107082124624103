
import React, { useState, useEffect } from "react";
import { Form } from "antd";
import DynamicForm from "../../component/Form";
import { CiLock, CiMail } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useUserLoginMutation } from "../../slices/loginRegisterSlice"
import { loginSuccess, userDetail, userStatus } from "../../slices/authSlice";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import images from '../../constant/images';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state?.persistedReducer);
  const isAuthenticated = userData?.isAuthenticated;
    const [form] = Form.useForm();
    const { Logo, SignInImage } = images;
    const [errorMessage, setErrorMessage] = useState('');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isverified = urlParams.get('isverified');
    if (isverified === 'true') {
        toast.success('You have successfully verified your email. Please login.');
    } if (isverified === 'false') {
        toast.error('Verification failed. Please try again or sign up.');
    }
    useEffect(() => {
        const url = window.location.href;
        const cleanUrl = url.split('?')[0];
        window.history.replaceState(null, '', cleanUrl);
    }, []);
    urlParams.delete('isverified');
    const newUrl = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, document.title, newUrl);

    const fields = [
        {
            component: 'input',
            type: 'email',
            name: 'email',
            placeholder: 'Enter Email',
            prefix: <CiMail
                style={{ color: '#F47939', fontSize: '25px' }} />,
            rules: [
                {
                    required: true,
                    message: 'Please input your email',
                },
            ],

        },
        {
            component: 'input',
            type: 'password',
            name: 'password',
            placeholder: 'Enter password',
            prefix: <CiLock
                style={{ color: '#F47939', fontSize: '25px' }} />,
            rules: [
                {
                    required: true,
                    message: 'Please input your password',
                },
            ],

        },
        {

            component: 'checkbox',
            type: 'checkbox',
            name: 'rememberMe',
            label: 'Remember Me'

        },
    ];

    const [userLogin, { isLoading }] = useUserLoginMutation();
   
    const navigateBasedOnUserStatus = (user, status,parent_id) => {
        if(parent_id){
            navigate("/dashboard");
        }else{
            if (status == "1" && user ) {
                navigate("/add_property");
            } else if (status == '3' && user ) {
             
                navigate("/dashboard");
            }
            else {
                 dispatch(userStatus(false)) 
                navigate("/profile");
            }
        }
       

    };
 

    const onFinish = async (value) => {
        try {
            const response = await userLogin(value);
            form.resetFields();

            if (response.error) {
                handleError(response.error.data.message);
                return;
            }

            const { token, user, status, parent_id} = response.data;
            localStorage.setItem("Token",token)
            if (token) {
                dispatch(
                    userDetail({
                        user: response?.data,
                    })
                );
                if (status == "1" || status == '3') {
                  
                    dispatch(userStatus(true));
                } 
                dispatch(loginSuccess({ user: response.data }));
                navigateBasedOnUserStatus(user, status,token,parent_id);
                toast.success('Login successful');
            }
        } catch (error) {
            handleError('An unexpected error occurred. Please try again.');
        }
    };

    const handleError = (message) => {
        setErrorMessage(message);
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

   


    return (
        <div className="authWrapper">
            <div className="authInner">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="col-md-6 whiteBg">
                            <div className="formWrapper">
                                <div className="logoWrap">
                                    <Logo />
                                </div>
                                <div className="titleWrap">
                                    <h2 className="authTitle">Login to your Account</h2>
                                    <p className="authSubTitle">Welcome, please sign-in to shackbox!</p>
                                </div>
                                <div className="buttonWrap">
                                    {/* <IconButton className={"fbBtn"} text="Facebook" icon={<FaFacebook />} />
                                    <IconButton className={"twitterBtn"} text="Facebook" icon={<FaTwitter />} /> */}
                                </div>
                                <div className="orTextRow">
                                    {/* <Divider>or continue with email</Divider> */}
                                </div>
                                <div className="formWrap">
                                    {errorMessage && (
                                        <div style={{ color: 'red' }}>
                                            {errorMessage}
                                        </div>
                                    )}
                                    <DynamicForm
                                        form={form}
                                        fields={fields}
                                        onFinish={onFinish}
                                        buttonName={'Sign In'}
                                        loading={isLoading}
                                    />
                                </div>
                                <div className="bottomTextRow">
                                    <span>Don’t have account?</span>
                                    <span className="text-wrapper-10">&nbsp;</span>
                                    <Link to='/signup' className="signUpLink">Create an Account</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mobHide">
                            <div className="imgWrapper">
                                <SignInImage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
