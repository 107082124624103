import React, { useEffect, useState } from "react";
import Title from "../../component/PageTitle";
import { Button, Table, Row, Col, Spin } from "antd";
import { TbRefresh } from "react-icons/tb";
import { MdOutlineAddHomeWork } from "react-icons/md";
import { TbUser } from "react-icons/tb";
import { GrBusinessService } from "react-icons/gr";
import { GrTransaction } from "react-icons/gr";
import { useDispatch } from "react-redux";
import * as expenseService from "../../services/expensesServices";
import { LoadingOutlined } from "@ant-design/icons";

export default function ExpenseCategory() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const DateFormatter = (date) => {
    const inputDate = date;

    const dateObj = new Date(inputDate);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = dateObj.getDate();
    const month = monthNames[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    const formatDate = `${day} ${month} ${year}`;
    return formatDate;
  };

  
  const [transactionData, setTransactionData] = useState([]);
  const [categorizedTransactionData, setCategorizedTransactionData] = useState(
    []
  );

  const handleTransactionData = () => {
    setLoader(true);
    dispatch(expenseService.categorizePlaidData())
      .then((response) => {
        setCategorizedTransactionData(response);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error({ error });
      });
  };

  function formatTransactionName(str) {
    let formattedStr = str.replace(/_/g, " ");

    formattedStr = formattedStr.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });

    return formattedStr;
  }

  const columns = [
    {
      title: "Provider Type",
      dataIndex: "providerType",
      render: (text, record, index) => (
        <>
          <div className="serial-no-container">
            <p className="serial-no">
              {formatTransactionName(record?.providerType)}
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Transaction Name",
      dataIndex: "transactionName",
      render: (text, record, index) => (
        <>  
          <div className="category-data-container">
            <p className="category-data-text">{record?.transaction_name}</p>
          </div>
        </>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text, record, index) => (
        <>
          <div className="amount-data-container">
            <p className="amount-data-text">${(record?.price ? record?.price: record?.amount)?.toFixed(2)}</p>
          </div>
        </>
      ),
    },
    {
      title: "Transiction Date",
      dataIndex: "transactionDate",
      render: (text, record, index) => (
        <>
          <div className="date-data-container">
            <p className="date-data-text">{DateFormatter(record?.date)}</p>
          </div>
        </>
      ),
    },
  ];
  const flatData = [];
  Object.keys(categorizedTransactionData)?.forEach((providerType) => {
    categorizedTransactionData[providerType]?.forEach((transaction) => {
      flatData?.push({
        providerType,
        ...transaction,
      });
    });
  });

  let total = 0;

  for (let category in categorizedTransactionData) {
    categorizedTransactionData[category]?.forEach((transaction) => {
      total += transaction?.price ?transaction?.price : transaction?.amount;
    });
  }



  return (
    <>
      <div className="mainWrapper">
        <div className="card titleCard mb-4">
          <div className="card-body d-flex justify-content-between align-items-center">
            <Title title="View Expense" />
            <Button
              onClick={handleTransactionData}
              className="generate-report-button"
            >
              Fetch Plaid Data{" "}
              <span className="refresh-span">
                <TbRefresh className="refresh-icon" />
              </span>
            </Button>
          </div>
        </div>
      </div>

      <div className="all-expenses-container">
        <Row className="all-expenses-row">
          <Col md={5} className="utility-provider-col">
            <div className="utility-provider-container">
              <Row className="utility-data-row">
                <Col md={8} className="utility-data-icon-col">
                  <div className="utility-icon-container">
                    <MdOutlineAddHomeWork className="utility-provider-icon" />
                  </div>
                </Col>
                <Col md={16} className="utility-data-container-col">
                  <div className="utility-data-container">
                    <p className="total-utilty-text">Utility Provider</p>
                    <p className="total-utilty-number">
                      {loader ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="large"
                        />
                      ) : (
                        categorizedTransactionData?.Utility_Provider?.length ||0
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={6} className="custom-provider-col">
            <div className="custom-provider-container">
              <Row className="custom-data-row">
                <Col md={8} className="custom-data-icon-col">
                  <div className="custom-icon-container">
                    <TbUser className="custom-provider-icon" />
                  </div>
                </Col>
                <Col md={16} className="custom-data-container-col">
                  <div className="custom-data-container">
                    <p className="total-custom-text">Custom Provider</p>
                    <p className="total-custom-number">
                      {loader ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="large"
                        />
                      ) : (
                        categorizedTransactionData?.Custom_Provider?.length || 0
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={6} className="service-provider-col">
            <div className="service-provider-container">
              <Row className="service-data-row">
                <Col md={8} className="service-data-icon-col">
                  <div className="service-icon-container">
                    <GrBusinessService className="service-provider-icon" />
                  </div>
                </Col>
                <Col md={16} className="service-data-container-col">
                  <div className="service-data-container">
                    <p className="total-service-text">Service Provider</p>
                    <p className="total-service-number">
                      {loader ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="large"
                        />
                      ) : (
                        categorizedTransactionData?.Service_Provider?.length || 0
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={7} className="transactions-provider-col">
            <div className="transactions-provider-container">
              <Row className="transactions-data-row">
                <Col md={8} className="transactions-data-icon-col">
                  <div className="transactions-icon-container">
                    <GrTransaction className="transactions-provider-icon" />
                  </div>
                </Col>
                <Col md={16} className="transactions-data-container-col">
                  <div className="transactions-data-container">
                    <p className="total-transactions-text">
                      Transactions Amount
                    </p>
                    <p className="total-transactions-number">
                      {loader ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="large"
                        />
                      ) : (
                        `$${
                          total !== undefined && total !== null
                            ? total.toFixed(2)
                            : "0.00"
                        }`
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      <div className="expense-table-heading-container">
        <p className="expense-table-heading">Expenses Table</p>

        <div className="expense-table-container">
          <Table
            pagination={{ pageSize: "5" }}
            columns={columns}
            dataSource={flatData}
            rowKey={(record) =>
              `${record.providerType}-${
                record?.transaction_name
                  ? record?.transaction_name
                  : record?.name
              }`
            }
          />
        </div>
      </div>
    </>
  );
}
