
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchDetail_Url } from "../Helper";

const getToken = () => {
  const persistedRoot = localStorage.getItem("persist:root");
  if (!persistedRoot) return null;
  const persistedRootObj = JSON.parse(persistedRoot);
  const userObj = persistedRootObj?.user
    ? JSON.parse(persistedRootObj.user)
    : null;
    if(userObj !== undefined && userObj !== null) {
      localStorage.setItem('Token',userObj?.user?.token);
  return userObj?.user?.token || null;
    }
};

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: fetchDetail_Url,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }else{
        headers.set("Authorization", `Bearer ${localStorage.getItem('Token')}`);
      }
      headers.set("Content-Type", "application/json; charset=UTF-8");
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    userProfile: builder.mutation({
      query: (newUser) => ({
        url: "profile",
        method: "PUT",
        body: newUser,
      }),
      invalidatesTags: ["User"],
    }),
    addProperty: builder.mutation({
      query: (newProperty) => ({
        url: "add/property",
        method: "POST",
        body: newProperty,
      }),
      invalidatesTags: ["Property"],
    }),
    addGroup: builder.mutation({
      query: (newProperty) => {
        return {
          url: "groups",
          method: "POST",
          body: newProperty,
        };
      },
      invalidatesTags: ["Group"],
    }),
    addUtility: builder.mutation({
      query: (newProperty) => ({
        url: `add/provider`,
        method: "POST",
        body: newProperty,
      }),
      invalidatesTags: ["Provider"],
    }),
    addCompany: builder.mutation({
      query: (newProperty) => ({
        url: `add/company`,
        method: "POST",
        body: newProperty,
      }),
      invalidatesTags: ["Company"],
    }),
    addField: builder.mutation({
      query: (newProperty) => ({
        url: `add/field`,
        method: "POST",
        body: newProperty,
      }),
      invalidatesTags: ["Field"],
    }),
    addPropertyProvider: builder.mutation({
      query: (newProperty) => ({
        url: "property/provider",
        method: "POST",
        body: newProperty,
      }),
      invalidatesTags: ["Propertyprovider"],
    }),
    addIncome: builder.mutation({
      query: (income) => ({
        url: "add/income",
        method: "POST",
        body: income,
      }),
      invalidatesTags: ["Income"],
    }),
    addCategory: builder.mutation({
      query: (category) => ({
        url: "add/category",
        method: "POST",
        body: category,
      }),
      invalidatesTags: ["Category"],
    }),
    changePassword: builder.mutation({
      query: (password) => ({
        url: "change/password",
        method: "POST",
        body: password,
      }),
      invalidatesTags: ["Password"],
    }),
    subAccount: builder.mutation({
      query: (data) => ({
        url: "sub_account",
        method: "POST",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      //     invalidatesTags: ['SubAccount'],
    }),
    setting: builder.mutation({
      query: ({ data, id }) => ({
        url: `setting/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Setting"],
    }),
    deletePropertyProvider: builder.mutation({
      query: (data) => ({
        url: `property-provider/${data}`,
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Delete"],
    }),
    editPropertyProvider: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `property-provider/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Edit"],
    }),
    editProperty: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `properties/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["SubAccount"],
    }),
    setting: builder.mutation({
      query: ({ data, id }) => ({
        url: `setting/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Setting"],
    }),
    deletePropertyProvider: builder.mutation({
      query: (data) => ({
        url: `property-provider/${data}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Delete"],
    }),
    editPropertyProvider: builder.mutation({
      query: ({ data, id }) => ({
        url: `property-provider/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Edit"],
    }),
    editProperty: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `properties/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["EditProperty"],
    }),
    deleteProperty: builder.mutation({
      query: (id) => ({
        url: `properties/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DeleteProperty"],
    }),
    editIncome: builder.mutation({
      query: ({ data, id }) => ({
        url: `incomes/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["EditIncome"],
    }),
    deleteIncome: builder.mutation({
      query: (id) => ({
        url: `incomes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DeleteIncome"],
    }),
    editGroup: builder.mutation({
      query: ({ data, id }) => ({
        url: `groups/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["EditGroup"],
    }),
    deleteGroup: builder.mutation({
      query: (id) => ({
        url: `groups/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DeleteGroup"],
    }),
    addPropertyType: builder.mutation({
      query: (data) => ({
        url: `add/property_type`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AddPropertyType"],
    }),
    addOwnerType: builder.mutation({
      query: (data) => ({
        url: `ownership_type`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AddOwnershipType"],
    }),
    editSubAccount: builder.mutation({
      query: ({ data, id }) => ({
        url: `sub-account/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["EditSubAccount"],
    }),
    getStatistics: builder.mutation({
      query: (data) => {
        return{
          url: "get_statistics",
          method: "POST",
          body:data
        }   
      },
      invalidatesTags: ["Statistics"],
    }),
    getTopRevenueProperties: builder.mutation({
      query: (data) => ({
        url: "top-revenue-properties",
        method: "POST",
        body:data
      }),
      invalidatesTags: ["Revenue"],
    }),
    logout: builder.mutation({
      query: () => {    
        return{
          url: 'logout',
          method: 'POST',
        }              
      },
      invalidatesTags: ["Logout"],
  }),
  getRevenueGrowthComparison: builder.mutation({
    query: (data) => ({
      url: "revenue-growth-comparison",
      method: "POST",
      body:data
    }),
    invalidatesTags: ["Comparison"],
  }),
  getTopProfitableProperties: builder.mutation({
    query: (data) => ({
      url: "top-profitable-properties",
      method: "POST",
      body:data
    }),
    invalidatesTags: ["Profitable"],
  }),
  deleteField: builder.mutation({
    query: (id) => ({
      url: `fields/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: ["DeleteField"],
  }),
  monthlyIncome: builder.mutation({
    query: (data) => {
      if(data){
        return{
          url: `monthly-income`,
          method: "POST",
          body:data
        }
      }
    },
    invalidatesTags: ["MonthlyIncome"],
  }),
  monthlyExpense: builder.mutation({
    query: (data) => {
      if(data){
        return{
          url: `monthly-expense`,
          method: "POST",
          body:data
        }
      }
      
    },
    invalidatesTags: ["MonthlyExpense"],
  }),
  unassignProperty: builder.mutation({
    query: ({userId,recordId}) => ({
      url: `user/${userId}/unassign-property/${recordId}`,
      method: "DELETE",
    }),
    invalidatesTags: ["UnassignProperty"],
  }),
  deactivateUser :builder.mutation({
    query: (id) => ({
      url: `user/deactivate/${id}`,
      method: "POST",
    }),
    invalidatesTags: ["DeactivateUser"],
  }),
 
  }),

  tagTypes: [
    "Revenue",
    "User",
    "Property",
    "Group",
    "Propertyprovider",
    "Provider",
    "Company",
    "Field",
    "Income",
    "Category",
    "Password",
    "SubAccount",
    "Setting",
    "Delete",
    "Edit",
    "EditProperty",
    "EditIncome",
    "DeleteIncome",
    "EditGroup",
    "DeletGroup",
    "AddPropertyType",
    "AddOwnershipType",
    "EditSubAccount",
    "Statistics",
     "Logout",
     "Comparison",
     "Profitable",
     "DeleteField",
     "MonthlyIncome",
      "MonthlyExpense",
      "UnassignProperty",
      "DeactivateUser",
      "CategorizePlaid"
  ],
});

export const {
  useUserProfileMutation,
  useAddPropertyMutation,
  useAddGroupMutation,
  useAddPropertyProviderMutation,
  useAddUtilityMutation,
  useAddCompanyMutation,
  useAddFieldMutation,
  useAddIncomeMutation,
  useAddCategoryMutation,
  useChangePasswordMutation,
  useSubAccountMutation,
  useSettingMutation,
  useDeletePropertyProviderMutation,
  useEditPropertyProviderMutation,
  useEditPropertyMutation,
  useDeletePropertyMutation,
  useEditIncomeMutation,
  useDeleteIncomeMutation,
  useEditGroupMutation,
  useDeleteGroupMutation,
  useAddPropertyTypeMutation,
  useAddOwnerTypeMutation,
  useEditSubAccountMutation,
  useGetStatisticsMutation,
  useGetTopRevenuePropertiesMutation,
  useLogoutMutation,
  useGetRevenueGrowthComparisonMutation,
  useGetTopProfitablePropertiesMutation,
  useDeleteFieldMutation,
  useMonthlyExpenseMutation,
  useMonthlyIncomeMutation,
  useUnassignPropertyMutation,
  useDeactivateUserMutation,

} = apiSlice;
