import React, { useEffect,useState } from "react";
import Title from "../../component/PageTitle";
import { Button, Checkbox, Form, Input, Modal} from "antd";
import { RiUser2Line } from "react-icons/ri";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { SlCalender } from "react-icons/sl";
import DynamicForm from "../../component/Form";
import { IoHomeOutline } from "react-icons/io5";
import { LiaUserEditSolid } from "react-icons/lia";
import { BsPlusCircle } from "react-icons/bs";
import CommonModal from "../../component/CommonModal";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetCompanyQuery,
  useGetUtilityQuery,
  useGetFieldsQuery,
  useGetPropertyProvidersQuery,
  useGetCategoryQuery,
} from "../../slices/getSlice";
import {
  useAddPropertyProviderMutation,
  useAddUtilityMutation,
  useAddCompanyMutation,
  useAddFieldMutation,
  useEditPropertyProviderMutation,
} from "../../slices/apiSlice";
import { toast } from "react-toastify";
import { PiHouseLine } from "react-icons/pi";
import { UserOutlined } from "@ant-design/icons";


const PropertyDetails = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate()
  const [utility, setUtility] = useState();
  const [company, setCompany] = useState();
  const[check , setCheck] = useState(false)
  const location = useLocation();
  const [selectedUtilityType, setSelectedUtilityType] = useState(null);
  const [FormInitialValues, setFormInitialValues] = useState(location?.state?.data);
  const [category, setCategory] = useState();
  const [FormInitialValuesDiff, setFormInitialValuesDiff] = useState({
    name: ""
  });
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [formCount, setFormCount] = useState(0);
  const [formData, setFormData] = useState([]);
  let propertyDetailState = location?.state
  const [addPropertyProvider] = useAddPropertyProviderMutation();
  const [editPropertyProvider] = useEditPropertyProviderMutation();
  const [addUtility] = useAddUtilityMutation();
  const [addCompany] = useAddCompanyMutation();
  const [addField] = useAddFieldMutation();
  const [modal1] = Form.useForm();
  const [modal2] = Form.useForm();
  const [modal3] = Form.useForm();
  const [isSaving ,setIsSaving] = useState(false)
  const[expenseType , setExpenseType] = useState('')
  let detail = {
    propertyId: location?.state?.data?.property_id,
    providerTypesId: location?.state?.providerType,
    skip:location?.state?.data?.property_id && location?.state?.providerType
  };
  const {
    data: getPropertyProviders,
    refetch: refetchPropertyProvider,
  } = useGetPropertyProvidersQuery(detail ? detail : "");

  const {
    data: getUtility, 
    refetch: refetchUtility,
  } = useGetUtilityQuery(location?.state?.data?.id);
  const {
    data: getCompany,
    refetch: refetchCompany,
  } = useGetCompanyQuery(selectedUtilityType ? selectedUtilityType : location?.state?.data?.providerId);
  const {
    data: getFields = [],
    refetch: refetchFields,
  } = useGetFieldsQuery(selectedUtilityType);

  const {
    data: getCategory,
    error:categoryError,
  } = useGetCategoryQuery('expense');
  const [disable, setDisable] = useState(location?.state?.data?.property_id ? false : true);
  const [modalfield, setModalField] = useState(false);
  const [modalprovider, setModalProvider] = useState(false);
  const [modalcompany, setModalCompany] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  
  useEffect(() => {
    if (getUtility && getUtility?.data) {
      const transformedOptions = getUtility?.data?.map((item) => ({
        label: item.provider_name,
        value: item.id,
      }));
      setUtility(transformedOptions);
    }
    
      if (getCategory && getCategory.data) {
        const transformedOptions = getCategory.data.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setCategory(transformedOptions);
      }
    if(categoryError?.status === 401){
      localStorage.clear();
      window.location.reload()
      navigate("/login");
    }

  }, [getUtility,getCategory]);

  useEffect(() => {
    refetchPropertyProvider();
  }, [getPropertyProviders]);

  useEffect(() => {
    if (getCompany && getCompany?.data) {
      const companies = getCompany?.data?.map((item) => ({
        label: item.company_name,
        value: item.company_id,
      }));
      setCompany(companies);
    }
  }, [getCompany]);


  const newFields = [
    {
      component: "input",
      type: "text",
      label: `Enter Field Name`,
      name: "name",
      placeholder: `Enter Field Name`,
      prefix: (
        <LiaUserEditSolid style={{ color: "#F47939", fontSize: "25px" }} />
      ),
      rules: [
        {
          required: true,
          message: `Please input your field name`,
        },
        {
          pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
          message: '  Field name  must only contain letters',
        },
      ],
    },
    {
      component: "select",
      label: "Select Type",
      name: "field_type",
      placeholder: "Please select type",
      suffixIcon: (
        <IoHomeOutline
          style={{
            color: "#F47939",
            fontSize: "25px",
            width: "30px",
            height: "30px",
          }}
        />
      ),
      rules: [
        {
          required: true,
          message: "Please select type",
        },
      ],
      options: [
        { label: "Date", value: "date" },
        { label: "Email", value: "email" },
        { label: "Number", value: "number" },
        { label: "Password", value: "password" },
        { label: "Phone Number", value: "phone" },
        { label: "Text", value: "text" },
        { label: "Url", value: "url" },
      ],
    },
  ];
  const providerFields = [
    {
      component: "input",
      type: "text",
      label: `Enter  Utility Name`,
      name: "name",
      placeholder: `Enter Utility Name`,
      prefix: (
        <LiaUserEditSolid style={{ color: "#F47939", fontSize: "25px" }} />
      ),
      rules: [
        {
          required: true,
          message: `Please input your utility name`,
        },
        {
          pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
          message: '  Utility name  must only contain letters',
        },
      ],
    },
  ];
  const companyFields = [
    {
      component: "input",
      type: "text",
      label: `Enter Company Name`,
      name: "name",
      placeholder: `Enter Company Name`,
      prefix: (
        <LiaUserEditSolid style={{ color: "#F47939", fontSize: "25px" }} />
      ),
      rules: [
        {
          required: true,
          message: `Please input your company name`,
        },
        {
          pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
          message: 'Company name  must only contain letters',
        },
      ],
    },
  ];
  const dynamicValue = location.state?.data?.form_fields || "{}";
  const parsedDynamicValue = JSON.parse(dynamicValue);

  const renderFormFields = () => {
    return Object.keys(parsedDynamicValue).map((key) => {
      const fieldValue = parsedDynamicValue[key];

      return {
        component: "input",
        name:key,
        type: 'text',
        label: key,
        defaultValue: fieldValue,
        colSpan: 12,
        static: false,       
      };
    });
  }

const dynamicfields = getFields?.data?.map((item) => {
  const rules = item.field_type === 'url' ? [
    {
      pattern: /^(www\.|https:\/\/|HTTP:\/\/|http:\/\/).*/i,
      message: `${item.name} must start with www., https://, or HTTP://`,
    },
  ] : item.field_type === 'phone' ? [
    {
      pattern: /^\+?[1-9]\d{0,2}\s?\d{10}$/,
      message: `${item.name} must be a valid phone number with an optional country code`,
    },
  ] : [];
  return {
    component: "input",
    type: (item.field_type === 'url' || item.field_type === 'phone') ? "text" : item.field_type,
    label: item.name,
    name: item.name,
    placeholder: `Enter ${item.name}`,
    colSpan: 12,
    static: false,
    rules,
  };
});



const handleOk = () => {
  setIsModalVisible(false);
};
const handleInputChange = (e) => {
  const value = e.target.value;
  if (/^\d*$/.test(value)) {
    setInputValue(parseInt(value) || 0);
  }
};

const handleCancel = () => {
  setIsModalVisible(false);
};

  const fields = [
    {
      component: "select",
      label: "Type of Utility",
      name: "provider_id",
      disabled: location?.state?.data?.property_id ? true : false,
      placeholder: "Select type of utility",
      suffixIcon: (
        <RiUser2Line style={{ color: "#F47939", fontSize: "25px" }} />
      ),
      onChange: (value) => {
        setFormInitialValues({
          company_id: null
        })
        setDisable(false);
        setSelectedUtilityType(value);
      },
      rules: [
        {
          required: true,
          message: "Please select utility type",
        },
      ],
      options: utility,
      colSpan: 12,
      static: true,
    },
    {
      component: "select",
      label: "Utility company",
      name: "company_id",
      placeholder: "Select Company",
      hidden: disable,
      suffixIcon: (
        <RiUser2Line style={{ color: "#F47939", fontSize: "25px" }} />
      ),
      rules: [
        {
          required: true,
          message: "Please select company",
        },
      ],
      options: company,
      colSpan: 12,
      static: true,
    },
    {
      component: "select",
      label: "Expense Type",
      name: "category_id",
      placeholder: "Please select type",
      rules: [
        {
          required: true,
          message: "Please select type",
        },
      ],
      disabled :location?.state?.data?.property_id ? true : false,
      onChange: (value) => {
       setExpenseType(value)
      },
      suffixIcon: (
        <PiHouseLine style={{ color: "#F47939", fontSize: "25px" }} />
      ),
      options: category,
      hidden: disable,
      colSpan: 8,
      static: true,
   
    },
    {
      component: "input",
      type: "number",
      label: "Amount",
      name: "amount",
      hidden: disable,
      placeholder: "Enter Amount",
      prefix: (
        <HiOutlineCurrencyDollar
          style={{ color: "#F47939", fontSize: "25px" }}
        />
      ),
      rules: [

        {
          pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
          message: 'Amount must be positive number',
        },

      ],

      colSpan: 8,
      static: true,
    },
    {
      component: "input",
      type: "date",
      label: "Start Date",
      name: "start_date",
      hidden: disable,
      disabled :location?.state?.data?.property_id ? true : false,
      placeholder: "Enter start date ",
      prefix: (
        <SlCalender
          style={{ color: "#F47939", fontSize: "25px", height: "20px" }}
        />
      ),

      colSpan: 8,
      static: true,
    },
    {
      component: "input",
      type: "date",
      label: "End Date",
      name: "due_date",
      hidden:  expenseType === 13 || location?.state?.data?.category_id === 'Recurring'? false:true ,
      disabled :location?.state?.data?.property_id ? true : false,
      placeholder: "Enter end date ",
      prefix: (
        <SlCalender
          style={{ color: "#F47939", fontSize: "25px", height: "20px" }}
        />
      ),

      colSpan: 12,
      static: true,
    },
    {
      component: "select",
      label: "Frequency",
      name: "frequency",
      hidden:expenseType === 13 || location?.state?.data?.category_id === 'Recurring'?false:true,
      disabled :location?.state?.data?.property_id ? true : false,
      placeholder: "Select the Frequency",
      suffixIcon: (
        <RiUser2Line style={{ color: "#F47939", fontSize: "25px" }} />
      ),

      options: [
        { value: "7", label: "Weekly" },
        { value: "14", label: "Fortnight" },
        { value: "30", label: "Monthly" },
        { value: "91", label: "Quarterly" },
        { value: "181", label: "Half yearly" },
        { value: "365", label: "Yearly" },
      ],
      colSpan: 12,
      static: true,
    },
    {
      component: 'checkbox',
      type: 'checkbox',
      hidden: disable,
      name: 'reminder',
      label: 'Remember Me',
      static: true,
      onChange:handleCheckboxChange

  },

    {
      component: "input",
      type: "text",
      hidden: isChecked?false:true,
      label: "Remainder (Please specify the day the reminder is needed on)",
      name: "reminder_duration",
      placeholder: "Enter Remainder",
      prefix: <UserOutlined style={{ color: "#F47939", fontSize: "25px" }} />,
      colSpan: 12,
      static: true,
    },
  ];
  const addExpense = async (staticValue, dynamicValue, formType) => {
   
    let count = formCount + 1;
    let storedFormData = [...formData];
    setIsSaving(true)
    try {
      const stateData = location?.state;
      let values = {};
      let company_id = null

      if (formType === "add") {
        setSelectedUtilityType('')
        setDisable(true)
        setIsChecked(false)
        values = {
          provider_type_id: stateData.data.id,
          property_id: location?.state?.propertyid,
          form_fields: JSON.stringify(dynamicValue),
          ...staticValue,
        };

        storedFormData.push(values);
        setFormData(storedFormData);
    
      } else {
        if (typeof staticValue.company_id === "string") {
          let company_data = company.find((companyData) => companyData.label === staticValue.company_id);
          company_id = company_data.value;
        } else {
          company_id = staticValue.company_id;
        }     
        values = {
          provider_type_id: stateData.data.provider_type_id,
          property_id: stateData?.propertyid ?? stateData.data.property_id,
          company_id: company_id ? company_id : stateData?.data?.companyId,
          form_fields: JSON.stringify(dynamicValue) ?? JSON.stringify(stateData?.data?.form_fields),
          frequency: staticValue?.frequency,
          due_date: staticValue?.due_date,
          amount: staticValue?.amount,
          provider_id: stateData?.data?.providerId ?? staticValue?.provider_id,
          category_id : staticValue?.category_id,
          start_date : staticValue?.start_date,
          reminder_duration : staticValue?.reminder_duration,
          reminder : staticValue?.reminder
        };
      }
    
      let id = location?.state?.data?.id
      if(count  < inputValue)
        {
          setFormCount(count)
          form.resetFields()
        }else{
          if (formType === "edit") {
            const response = await editPropertyProvider({ data: values, id });
            if (response.data) {
              refetchPropertyProvider()
              navigate('/view_property', {
                state: {
                  provider_type_id: location.state.data.provider_type_id,
                  property_id: location.state.data.property_id,
                  propertyData: propertyDetailState.propertyData
                }
              })
              toast.success("Property provider has been updated successfully");
              form.resetFields();
            } else {
              toast.error(`${response?.error?.data?.message}`);
            }
          } else {
        
            const response = await addPropertyProvider({providers:storedFormData});
            if (response.data) {   
                
              refetchPropertyProvider()
             
              navigate('/view_property', {
                state: {
                  // provider_type_id: response.data.data.provider_type_id,
                  // property_id: response.data.data.property_id,
                  propertyData: propertyDetailState.propertyData
                }
              })
              toast.success("Property provider has been created successfully");
              form.resetFields();
              setExpenseType('')
            
              
            
            } else {
              toast.error(`${response?.error?.data?.message}`);
            }
          }
          
        }
    
    } catch {
      
    } finally {
      setIsSaving(false)
}
  
  };
  const fieldAdd = async (values) => {

    let data = {
      provider_type_id: location?.state?.data?.id,
      provider_id: selectedUtilityType ? selectedUtilityType : location?.state?.data?.providerId,
      ...values,
    };

    try {
      const response = await addField(data);
      if (response.data) {
        setModalField(false);
        refetchFields();
        modal1.resetFields();
        toast.success("Field has been created successfully");
      }
      else {
        toast.error(`${response?.error?.data?.message}`);
        modal1.resetFields();
      }
    } catch (error) {
      toast.error("Failed to add field");
    }
  };
  const providerAdd = async (values) => {
    let data = {
      provider_type_id: location?.state?.data.id ? location?.state?.data.id : location.state.data.provider_type_id.id,
      ...values,
    };
    try {
      const response = await addUtility(data);
      if (response.data) {
        setModalProvider(false);
        modal2.resetFields();
        refetchUtility();
        toast.success("Utility added successfully");
      } else {
        toast.error(`${response?.error?.data?.message}`);
      }
    } catch (error) {
      toast.error("Failed to add Utility type");
    }
  };
  const companyAdd = async (values) => {
    let data = {
      provider_type_id: location?.state?.data?.id,
      provider_id: selectedUtilityType ? selectedUtilityType : location?.state?.data?.providerId,
      ...values,
    };

    const response = await addCompany(data);
    if (response.data) {
      setModalCompany(false);
      modal3.resetFields();
      refetchCompany();
      toast.success("Company has been added successfully");
    } else {
      toast.error(`${response?.error?.data?.message}`);
    }
  };

  const anotherButtonElement = (
    <Button
      type="primary"
      className="btnOutlined w-150"
      disabled={location?.state?.data?.property_id || disable ? true : false}
      onClick={() => {
        setModalField(true);
        setFormInitialValuesDiff({ name: "", field_type: "" })
      }}
    >
      Add Field
    </Button>
  );

  return (
    <div className="mainWrapper">
      <div className="card titleCard mb-4">
        <div className="card-body">
          <Title title="Property Details" />
          <p>{location?.state?.propertyName}</p>
        </div>
      </div>
     {formCount < inputValue || formCount == 0 ?
      <div className="card tableCard mt-3"  >
        <div className="card-body pt-0">
          <div className="topRow">
            <div className="topTile">
              <BsPlusCircle />

              <h5>{`${location?.state?.data?.name
                ? location?.state?.data?.name
                : location.state?.providerTypeName
                } Details`}</h5>
            </div>
            <div className="buttonSection">
              <Button
                type="primary"
                htmlType="submit"
                disabled={location?.state?.data?.property_id ? true : false}
                className="btnOutlined me-3"
                onClick={() => {

                  setModalProvider(true);
                  setFormInitialValuesDiff({ name: "" })
                }}
              >
                Add Utility
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                className="btnFilled"
                disabled={disable ? true : false}
                onClick={() => {
                  setModalCompany(true);
                  setFormInitialValuesDiff({ name: "" })
                }}
              >
                Add company
              </Button>
            </div>
          </div>
          <div className="contetnWrapper mt-4">
            <div className="row">
         
              <div className="col-md-12">
                <DynamicForm
                  form={form}
                  fields={fields}
                   onFinish={addExpense}
                  buttonName={"Save"}
                  anotherButton={anotherButtonElement}
                  dynamicfields={dynamicfields ?? renderFormFields()}
                  onUtilityTypeChange={setSelectedUtilityType}
                  selectedValue={selectedUtilityType}
                  FormInitialValues={FormInitialValues}
                  formType={FormInitialValues?.companyId ? "edit" : "add"}
                  ButtonDisable={isSaving}
                />
              
          
              </div>
                
            </div>
          </div>
        </div>
      </div> :''}
      <CommonModal
        open={modalfield}
        close={() => setModalField(false)}
        FormInitialValues={FormInitialValuesDiff}
        form={modal1}
        onFinish={fieldAdd}
        fields={newFields}
        title={"Add Field"}
      />
      <CommonModal
        open={modalprovider}
        close={() => setModalProvider(false)}
        form={modal2}
        onFinish={providerAdd}
        FormInitialValues={FormInitialValuesDiff}
        fields={providerFields}
        title={"Add Utility"}
      />
      <CommonModal
        open={modalcompany}
        close={() => setModalCompany(false)}
        form={modal3}
        FormInitialValues={FormInitialValuesDiff}
        onFinish={companyAdd}
        fields={companyFields}
        title={"Add Company"}
      />
     {FormInitialValues?.companyId ? '': <Modal
        title="Enter Form count"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
       
      >
        <Input
          placeholder="Type something..."
          value={inputValue}
            className="form-control"
          onChange={(e) => handleInputChange(e)}
        />
         
      </Modal>} 

    </div>
  );
};

export default PropertyDetails;
