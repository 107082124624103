import { UserOutlined } from "@ant-design/icons/lib";
import React, { useEffect, useState } from "react";
import { Form, Image } from "antd";
import { RiUser2Line } from "react-icons/ri";
import DynamicForm from "../../component/Form";
import {
  useGetCurrencyQuery,
  useGetUserDetailQuery,
} from "../../slices/getSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useSettingMutation } from "../../slices/settingSlice";
import { saveCurrency } from "../../slices/authSlice";

const Setting = () => {
  const dispatch = useDispatch()
  const [currency, setCurrency] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [imageurl, setImageUrl] = useState([]);
  const [settingId, setSettingid] = useState([]);
  const [formIntinalValue, setFormIntinalValue] = useState("");
  const [form] = Form.useForm();
  const [isSaving ,setIsSaving] = useState(false)
  const fields = [
    {
      component: "input",
      type: "file",
      label: "Logo",
      name: "logo",
      placeholder: "Select the logo",
      colSpan: 12,
    },
    {
      component: "select",
      label: "Currency",
      name: "currency",
      placeholder: "Please select the currency",
      suffixIcon: (
        <RiUser2Line style={{ color: "#F47939", fontSize: "25px" }} />
      ),
      options: currency,
      colSpan: 12,
    },
    
  ];
  const userData = useSelector((state) => state.persistedReducer);
  const [setting] = useSettingMutation();
  const { data: getCurrency } = useGetCurrencyQuery();
  const {
    data: getUserDetail,
    refetch,
  } = useGetUserDetailQuery();
 
  useEffect(() => {
    if (getCurrency && getCurrency.data) {
      const transformedOptions = getCurrency.data.map((item) => ({
        label: `${item.name}${" "}${item.symbol}`,
        value: item.id,
      }));
      setCurrency(transformedOptions);
    }
    if (getUserDetail && getUserDetail.data) {
      const res = getUserDetail.data.setting;

      setSettingid(res?.id);
      setImageUrl(res?.logo);
      const updatedUserProfileData = {
        currency: res?.currency_id,
        logo:`https://shackbox-api.aleaspeaks.com/${res?.logo}`
      };
      dispatch(saveCurrency(getUserDetail?.data?.setting))
      setFormIntinalValue(updatedUserProfileData);
      form.setFieldsValue(updatedUserProfileData);
    }
  }, [getCurrency, getUserDetail]);

  const onFinish = async (values) => {
    setIsSaving(true)
    try {
      let data = new FormData();

      data.append("logo", values?.logo?.originFileObj);
      data.append("currency", values?.currency);
    

      const response = await setting({ data, id: settingId });
      if (response.data) {
        refetch();
        toast.success("Setting has been updated successfully");
      } else {
        setErrorMessage(response.error.data.message);
        setTimeout(() => {
          setErrorMessage('');
        }, 5000)
      }
    } catch {
      
    } finally {
      setIsSaving(false)
    }
 
  };

  return (
    <>
    
      {errorMessage && <div style={{color: "red"}}>{errorMessage}</div>}
      <Image
    width={200}
    src={`https://shackbox-api.aleaspeaks.com/${imageurl}`}/>
      <DynamicForm
        form={form}
        fields={fields}
        onFinish={onFinish}
        buttonName={"Save"}
        FormInitialValues={formIntinalValue}
        ButtonDisable={isSaving}
      />
    </>
  );
};

export default Setting;
