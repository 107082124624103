
import { theme, Avatar, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Header } from "antd/es/layout/layout";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const TopHeader = () => {
    const userData = useSelector((state) => state?.persistedReducer?.user?.user?.user);
    const user = useSelector((state) => state?.persistedReducer?.detail?.user?.data?.first_name);
     const data = useSelector((state) => state.persistedReducer);
  
    const parent_id = data?.user?.user?.parent_id;
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const menuItems = [
        {
            key: '0',
            label: <Link to="/profile" className="link">My Profile</Link>,
       
        },
    ];

    return (
        <Header
            style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                width: "100%",
                background: colorBgContainer,
            }}
        >
            <div className="row">
                <div className="col-md-9">
                </div>
                <div className="col-md-3 text-end">
                    <div className="d-flex justify-content-end align-items-center">
                        {/* <div className="notificationIcon">
                            <BellOutlined />
                        </div> */}
                        {parent_id?
                       (<div className="profiles">
                     
                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar
                                    style={{
                                        backgroundColor: '#F47939',
                                        cursor: 'pointer',
                                    }}
                                    icon={<UserOutlined />}
                                />
                                <span className="username " style={{ marginLeft: '10px' }}>
                                    {user ? user : userData}

                                </span>
                            </div>
                     
                            </div>) : 
                            (<div className="profiles">
                            <Dropdown menu={{ items: menuItems }} trigger={['click']}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                    <Avatar
                                        style={{
                                            backgroundColor: '#F47939',
                                            cursor: 'pointer',
                                        }}
                                        icon={<UserOutlined />}
                                    />
                                    <span className="username " style={{ marginLeft: '10px' }}>
                                        {user ? user : userData}

                                    </span>
                                </div>
                            </Dropdown>
                        </div>)}
                       
                    </div>
                </div>
            </div>
        </Header>
    )
}
export default TopHeader;