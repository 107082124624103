import React, { useEffect, useState } from "react";
import ChartRevenue from "../../HelperCharts/RevenueChart";
import Title from "../../component/PageTitle";
import { BsBuildings } from "react-icons/bs";
import { GiReceiveMoney, GiExpense } from "react-icons/gi";
import { Table, Select, Spin } from "antd";
import ChartProfit from "../../HelperCharts/ProfitChart";
import { useSelector } from "react-redux";
import {
  useGetStatisticsMutation,
  useGetTopProfitablePropertiesMutation,
  useGetTopRevenuePropertiesMutation,
} from "../../slices/apiSlice";
import { LuRefreshCcw } from "react-icons/lu";
import { useGetPropertiesQuery, useGetUserDetailQuery } from "../../slices/getSlice";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const Dashboard = () => {

  const currencyGlobal = useSelector(
    (state) => state?.persistedReducer?.currency?.currency_symbol
  );
  const {
    data: getUserDetail,
} = useGetUserDetailQuery();
const defaultCurrency = getUserDetail?.data.setting.currency_symbol

  let navigate = useNavigate()
  const currency = currencyGlobal ? currencyGlobal :defaultCurrency;
  const [showNewSelect, setShowNewSelect] = useState(false);
  const[properties,setProperties] = useState()
  const [newSelectOptions, setNewSelectOptions] = useState([]);
  const [filterValue,setFilterValue] = useState()
  const [selectedValue, setSelectedValue] = useState();
  const [selectedProperty,setSelectedProperty] = useState([])
  const [statistics, setStatistics] = useState("");
  const [revenueProperty, setRevenueProperty] = useState("");
  const [profitProperty, setProfitProperty] = useState();
  const [data, setData] = useState({
    filter_type: "",
    filter_value: "",
     property_ids: " "
  });

  const [
    statisticsData,
    { isLoading: isStatisticsLoading, isError: isStatisticsError },
  ] = useGetStatisticsMutation();
  const {
    data: getProperties,
  } = useGetPropertiesQuery();
 
  const [topRevenuePropertiesData] = useGetTopRevenuePropertiesMutation();
  const [topProfitablePropertiesData] = useGetTopProfitablePropertiesMutation();
  let tokenData = localStorage?.getItem('Token');

  useEffect(() => {
    if(tokenData !== undefined){
    handleMonthChange();
    }
  }, [tokenData]);
  useEffect(() => {
    if (getProperties && getProperties?.data) {
      const transformedOptions = getProperties?.data?.map((item) => ({
        label: item.name_of_property,
        value: item.id,
      }));
      setProperties(transformedOptions);
    }else{

    }
  }, [getProperties]);
  function absString(n) {
   let numberString = n.toString();
    if (numberString[0] === '-') {
      return numberString.substring(1);
    }
    else {
      return numberString;
    }
  }
  const netProfit = statistics?.net_profit || 0;
  const numberWithoutCommas = netProfit?netProfit?.replace(/,/g, ''):'';
  let netProfitNumber = absString(netProfit);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Property Name",
      dataIndex: "name_of_property",
      key: "name",
    },
  ];

    const handleChange = (value) => {
      setSelectedProperty(value);
        handleMonthChange(value)    
    };

  const handleDateChange = (value) => {
    setSelectedValue(value);

    switch (value) {
      case "monthly": // Monthly
        setNewSelectOptions([
          { value: "January", label: "January" },
          { value: "February", label: "February" },
          { value: "March", label: "March" },
          { value: "April", label: "April" },
          { value: "May", label: "May" },
          { value: "June", label: "June" },
          { value: "July", label: "July" },
          { value: "August", label: "August" },
          { value: "September", label: "September" },
          { value: "October", label: "October" },
          { value: "November", label: "November" },
          { value: "December", label: "December" },
        ]);
        setShowNewSelect(true);
        break;
      case "quarterly": // Quarterly
        setNewSelectOptions([
          { value: "Q1", label: "Q1 (Jan-Mar)" },
          { value: "Q2", label: "Q2 (Apr-Jun)" },
          { value: "Q3", label: "Q3 (Jul-Sep)" },
          { value: "Q4", label: "Q4 (Oct-Dec)" },
        ]);
        setShowNewSelect(true);
        break;
      case "halfyearly": // Half Yearly
        setNewSelectOptions([
          { value: "H1", label: "H1 (Jan-Jun)" },
          { value: "H2", label: "H2 (Jul-Dec)" },
        ]);
        setShowNewSelect(true);
        break;
      default:
        setShowNewSelect(false);
        break;
    }
  };
 
  const handleMonthChange = async (value) => {
 if(typeof value === 'string'){
  setFilterValue(value)
 }
    const values = {
      filter_type: selectedValue ?? '' ,
      filter_value:typeof value === 'string'?value : filterValue,
     property_ids:typeof value === 'string'?selectedProperty:value
    };
    setData(values);
    const res = await statisticsData(values);
    if (res.data) {
      setStatistics(res?.data);
    }
    const getRevenue = await topRevenuePropertiesData(values);
    if (getRevenue?.data) {
      setRevenueProperty(getRevenue?.data);
    }

    const profitProperty = await topProfitablePropertiesData(values);

    if (profitProperty?.data) {
      setProfitProperty(profitProperty?.data);
    }else if(profitProperty?.error?.status == 401){   
      localStorage.clear();
      window.location.reload()
      navigate("/login");
    }
  };

  return (

    <div className="mainWrapper">
      <div className="card chartCard mb-4">
        <div className="card-body">
          <div className="headerRow">
            <Title title="Dashboard" />
            <div className="filterSection">
              <Select
              mode="multiple"
              value={selectedProperty}
              placeholder="Filter by Properties"
                style={{
                  width: 200,
                  marginRight: "1rem",
                }}
                onChange={(value)=>handleChange(value)}
                options={properties}
              />

              <Select
                 placeholder="Filter by Date"
                value={selectedValue}
                style={{ width: 150 }}
                onChange={handleDateChange}
                options={[
                  { value: "monthly", label: "Monthly" },
                  { value: "quarterly", label: "Quarterly" },
                  { value: "halfyearly", label: "Half Yearly" },
                  // { value: "custom", label: "Custom" },
                ]}
              />
              {showNewSelect && (
                <Select
                  style={{ width: 150, marginLeft: 10 }}
                  placeholder="Select Here"
                  onChange={(value) => handleMonthChange(value)}
                  options={newSelectOptions}
                />
              )}
              <LuRefreshCcw
                className="customRefresh-icon"
                onClick={() => {
                  handleDateChange("");
                  handleMonthChange();
                  handleChange()
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="chartWrapper">
        <div className="row">
          <div className="col-md-4">
            <div className="card chartCard mb-4">
              <div className="card-body">
                <div className="topBox">
                  <span className="dashIcon">
                    <BsBuildings />
                  </span>
                  <div className="dashText">
                    <p>Total Properties</p>
                    {isStatisticsLoading && <Spin />}
                    {isStatisticsError && <div>Error loading statistics.</div>}
                    {!isStatisticsLoading && !isStatisticsError && (
                      <>
                        <h4>{statistics?.total_properties}</h4>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card chartCard mb-4">
              <div className="card-body">
                <div className="topBox">
                  <span className="dashIcon">
                    <GiReceiveMoney />
                  </span>
                  <div className="dashText">
                    <p>Total Revenue</p>
                    {isStatisticsLoading && <Spin />}
                    {isStatisticsError && <div>Error loading statistics.</div>}
                    {!isStatisticsLoading && !isStatisticsError && (
                      <>
                        <h4>{`${currency}${statistics?.total_revenue}`}</h4>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card chartCard mb-4">
              <div className="card-body">
                <div className="topBox">
                  <span className="dashIcon">
                    <GiExpense />
                  </span>
                  <div className="dashText">
                    <p>Total Expense</p>
                    {isStatisticsLoading && <Spin />}
                    {isStatisticsError && <div>Error loading statistics.</div>}
                    {!isStatisticsLoading && !isStatisticsError && (
                      <>
                        <h4>{`${currency}${statistics?.total_expense}`}</h4>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-12">
            <div
              className={
                numberWithoutCommas>0 
                  ? "card chartCard netProfiteCard"
                  : "card chartCard netLossCard"
              }
            >
              <div className="card-body">
                {isStatisticsLoading && <Spin />}
                {isStatisticsError && <div>Error loading statistics.</div>}
                {!isStatisticsLoading && !isStatisticsError && (
                  <>
                    <h2>
                      {numberWithoutCommas>0
                        ? `Net Profit: ${currency}${netProfit}`
                        : `Net Loss:  ${currency}${netProfitNumber}`}
                    </h2>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-5">
            <div className="card chartCard mb-4 h-100">
              <div className="card-header">
                <h5 className="card-title">
                  Top 5 Revenue Generating Properties
                </h5>
              </div>
              <div className="card-body">
                <Table
                  rowKey={(record) => record.id}
                  bordered
                  pagination={false}
                  dataSource={revenueProperty?.topProperties}
                  columns={columns}
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="card chartCard mb-4 h-100">
              <div className="card-body">
                <ChartRevenue data={data} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="card chartCard mb-4 h-100">
              <div className="card-body">
                <ChartProfit data={data} />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card chartCard mb-4 h-100">
              <div className="card-header">
                <h5 className="card-title">Top 5 Profitable Properties</h5>
              </div>
              <div className="card-body">
                <Table
                  rowKey={(record) => record.id}
                  bordered
                  pagination={false}
                  dataSource={profitProperty?.topProperties}
                  columns={columns}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
