import React, { useState } from "react";
import Title from "../../component/PageTitle";
import { Button,Spin } from "antd";
import { TbRefresh } from "react-icons/tb";
import * as expenseService from "../../services/expensesServices";
import { useDispatch } from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { LoadingOutlined } from '@ant-design/icons';

export default function ReportAnalysis() {
  const dispatch = useDispatch();
  const [reportAnalysis, setReportAnalysisData] = useState({ monthly_report: {}, financial_habits_analysis: {} });
  const [loader, setLoader] = useState(false);

  const handleTransactionData = () => {
    setLoader(true);
    dispatch(expenseService.reportAnalysis())
      .then((response) => {
        setReportAnalysisData(response);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error({ error });
      });
  };

  const { monthly_report = {}, financial_habits_analysis = {} } = reportAnalysis;

  const getTopSpendingCategoriesData = (report) => {
    const categories = [];
    Object.values(report).forEach(month => {
      Object.entries(month.top_spending_categories || {}).forEach(([category, subcategories]) => {
        Object.entries(subcategories).forEach(([subcategory, amount]) => {
          categories.push({
            name: `${category} - ${subcategory}`,
            y: amount,
          });
        });
      });
    });
    return categories;
  };

  const topSpendingCategoriesData = getTopSpendingCategoriesData(monthly_report);

  const optionsTopSpendingCategories = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Top Spending Categories',
    },
    series: [{
      name: 'Amount',
      data: topSpendingCategoriesData,
    }],
  };

  const getFinancialHabitsData = (analysis) => {
    return Object.entries(analysis.top_spending_categories || {}).map(([category, amount]) => ({
      name: category,
      y: amount,
    }));
  };

  const financialHabitsData = getFinancialHabitsData(financial_habits_analysis);

  const optionsFinancialHabits = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Financial Habits Analysis - Top Spending Categories',
    },
    series: [{
      name: 'Amount',
      data: financialHabitsData,
    }],
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const shouldRenderDiv = !isEmptyObject(monthly_report) && !isEmptyObject(financial_habits_analysis);


  return (
    <>
      <div className="mainWrapper">
        <div className="card titleCard mb-4">
          <div className="card-body d-flex justify-content-between align-items-center">
            <Title title="Report Analysis" />
            <Button onClick={handleTransactionData} className="generate-report-button">
            {loader ? (
              <>
              <Spin indicator={<LoadingOutlined spin />} size="large" />&nbsp;...Processing Data

              </>
            ):(
              <>
              Fetch Plaid Data{" "}
              <span className="refresh-span">
                <TbRefresh className="refresh-icon" />
              </span>
              </>
            )}
             
            </Button>
          </div>
        </div>
      </div>
      {shouldRenderDiv && (
        <div>
          <HighchartsReact highcharts={Highcharts} options={optionsTopSpendingCategories} />
          <HighchartsReact highcharts={Highcharts} options={optionsFinancialHabits} />
        </div>
      )}
    </>
  );
}
