import React, {  useEffect, useState } from "react";
import { Button, Modal, Pagination, Table, Tooltip, Spin } from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import Title from "../../component/PageTitle";
import { useNavigate } from "react-router-dom";
import { FaEdit,  } from "react-icons/fa";

import { useGetIncomeQuery } from "../../slices/getSlice";
import { useSelector } from "react-redux";
import { RxCrossCircled } from "react-icons/rx";
import { useDeleteIncomeMutation } from "../../slices/apiSlice";
import { HiOutlineTrash } from "react-icons/hi2";
import { toast } from "react-toastify";

const Income = () => {
  const currencyGlobal = useSelector((state) => state?.persistedReducer?.currency?.currency_symbol)
  const currency = currencyGlobal ? currencyGlobal : '';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState("");
  const pageSize = 5
  const { data: getIncome, isLoading, isError, refetch,error } = useGetIncomeQuery();
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    refetch()
    if(error?.status == 401){
      localStorage.clear();
      window.location.reload()
      navigate("/login");
    }
  }, [getIncome])
  const [deleteIncome, { }] = useDeleteIncomeMutation();
  const handleOk = async () => {
    const res = await deleteIncome(selectedRecord);
    if (res?.data?.message ) {
      refetch();
      setIsModalOpen(false);
      toast.success(res?.data?.message)
    }
    else{
      toast.error("Not able to delete income")
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedRecord("");
  };
  const dataSource = getIncome?.data ? getIncome.data.map((item) => ({
    property_id: item.property.name_of_property,
    propertyId: item.property.id,
    category_id: item.category.name,
    categoryId: item.category.id,
    amount: item.amount,
    start_date: item.start_date,
    end_date: item.end_date,
    id: item.id
  })) : [];

  const handleChangePage = (page) => {
    setCurrentPage(page);

  };
  const totalItems = dataSource ? dataSource.length : 0;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalItems);
  const displayedItems = dataSource.slice(startIndex, endIndex);
  const Columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      render: (_, record, index) =>
        ((currentPage - 1) * pageSize + index + 1)

    },
    {
      title: "Property Name",
      dataIndex: "property_id",
    },
    {
      title: "Category",
      dataIndex: "category_id",
    },
    {
      title: "Income",
      dataIndex: "amount",
      render: (amount) => `${currency}${amount}`,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <div className="action-container">
            <Tooltip title="Edit">
              <button
                className="btn btnEdit me-2"
                onClick={() => { navigate('/add_income', { state: record }) }}
              >
                <FaEdit />
              </button>
            </Tooltip>
            <Tooltip title="Delete">
              <button
                className="btn btnDelete me-2"
                onClick={() => {
                  setSelectedRecord(record.id);
                  setIsModalOpen(true);
                }}
              >
                <HiOutlineTrash />
              </button>
            </Tooltip>
          </div>

        );
      },
    },
  ];

  return (
    <div className="mainWrapper">
      {/* Button and Title Section */}
      <div className="card titleCard mb-4">
        <div className="card-body">
          <Title title="All Income" />
          <Button
            type="primary"
            className="link"
            style={{
              backgroundColor: "#F47A3A",
            }}
            onClick={() => (navigate('/add_income'))}
          >
            {"Add Income"}{" "}
            <IoIosAddCircleOutline style={{ marginLeft: "10px" }} />

          </Button>
        </div>
      </div>

      <div className="card tableCard">
        <div className="card-body">
          {isLoading ? (
            <Spin size="large" style={{display:"flex" , alignItems:"center", justifyContent:"center"  }} />
          ) : isError && displayedItems.length < 0 ? (
            <h4>An error occurred</h4>
          ) : (
            <div className="table-responsives">
              {displayedItems.length > 0 ? (
                <>
                  <Table
                    rowKey={record => record.id}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={displayedItems}
                    columns={Columns}
                    pagination={false}
                  />
                  <Pagination
                    className="mt-4"
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalItems}
                    onChange={handleChangePage}
                  />
                </>
                  ) : (
                    <h4>No data found</h4>  
              )}

            </div>
          )}
        </div>
      </div>
      <Modal
        className="deleteModal"
        title="Delete Income"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="iconRow">
          <RxCrossCircled />
        </div>
        <div className="textRow">
          <h3>Are you sure?</h3>
          <p>Do you really want to delete this income?</p>
        </div>
        <div className="buttonRow">
          <Button className="btnOutlined me-2" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="btnFilled text-white" onClick={() => handleOk()}>
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default Income;