import React, { useEffect, useRef, useState } from "react";
import Title from "../../component/PageTitle";
import { Button, Tooltip, Space, Input, Table, Modal, Collapse, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
    useGetProviderTypeFromPropertyProviderQuery,
    useGetPropertyProvidersQuery,
    useGetUtilityCategoryQuery
} from "../../slices/getSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeletePropertyProviderMutation } from "../../slices/apiSlice";
import { FaEdit } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { HiOutlineTrash } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


const ViewProperty = () => {
    const [UtilityCategory, setUtilityCategory] = useState();
    const [UtilityCategoryName, setUtiltityCategoryName] = useState();
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const propertyId = location?.state?.propertyData?.id;
    const propertyName = location?.state?.propertyData?.name_of_property;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState("");
    const [deletepropertyprovider, { }] = useDeletePropertyProviderMutation();
    const {
        data: getUtilityCategory = [],
    } = useGetUtilityCategoryQuery();

    const currencyGlobal = useSelector((state) => state?.persistedReducer?.currency?.currency_symbol)
    const currency = currencyGlobal ? currencyGlobal : '';
    const [isClicked, setIsClicked] = useState(false);
    let propertyDataState = location?.state?.propertyData;

    const {
        data: getProviderType = [],
        isLoading: isProviderTypeLoading,
        refetch: refetchProvider,
    } = useGetProviderTypeFromPropertyProviderQuery(
        propertyId ? propertyId : location?.state?.property_id
    );


    const [propertyData, setPropertyData] = useState(location?.state?.propertyData);

    useEffect(() => {
        refetchProvider();
        if (getProviderType.length > 0) {
            const firstProviderTypeId = getProviderType[0]?.provider_type_id || null;
            setUtilityCategory(firstProviderTypeId);
        }
    }, [getProviderType]);

    let data = {
        skip: isClicked,
        propertyId: propertyId ? propertyId : location?.state?.property_id,
        providerTypesId: UtilityCategory
            ? UtilityCategory
            : location?.state?.provider_type_id,
        propertyName: propertyName,
    };

    const {
        data: getPropertyProviders,
        isLoading: isPropertyProvidersLoading,
        refetch: refetchPropertyProvider,
        error
    } = useGetPropertyProvidersQuery(data);

    useEffect(() => {
        refetchPropertyProvider();
        if(error?.status == 401){
            localStorage.clear();
            window.location.reload()
            navigate("/login");
        }
    }, [getPropertyProviders]);

    const handleOk = async (id) => {
        const res = await deletepropertyprovider(selectedRecord);
        if (res?.data?.message) {
            refetchPropertyProvider();
            refetchProvider()
            setIsModalOpen(false);
            toast.success(res?.data?.message)
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedRecord("");
    };



    getPropertyProviders ? (
        getPropertyProviders?.data?.flatMap((item, index) => {
            const companies = item?.company?.company_name
                .split(", ")
                .map((name) => name?.split(" , "))
                .flat();
            return companies?.map((company, i) => ({
                key: index * 100 + i,
                provider: item?.provider?.provider_name,
                company: company,
                amount: item?.amount,
                id: item?.id,
                provider_id: item?.provider.id,
                company_id: item?.company?.company_id,
                property_id: item?.property_id,
            }));
        })
    ) : (
        <p>No data found</p>
    );

    const [pagesize, setPageSize] = useState(5);
    const [current, setCurrent] = useState(1);
    const [showGroup, setShowGroup] = useState(false);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {

        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        className=""
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Clear Search
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const showGroupSection = () => {
        setShowGroup(!showGroup);
    };

    const manageExpense = () => {
        
        setIsClicked(true);
    };

    const handleClick = (detail) => {

        navigate("/property_details", {
            state: {
                data: detail,
                propertyData: propertyDataState,
                propertyid: propertyId,
                propertyName: propertyName,
            },
        });
    };

    const transformedData = getPropertyProviders?.data?.flatMap((item, index) => {
        const companies = item?.company?.company_name
            .split(", ")
            .map((name) => name?.split(" , "))
            .flat();
        return companies?.map((company, i) => ({
            key: index * 100 + i,
            provider_id: item?.provider?.provider_name,
            company_id: company,
            companyId: item?.company_id,
            amount: item?.amount,
            due_date: item?.due_date,
            id: item?.id,
            provider_id: item?.provider?.provider_name,
            frequency: item?.frequency,
            property_id: item?.property_id,
            provider_type_id: item?.provider_type_id,
            providerId: item?.provider_id,
            form_fields: item?.form_fields,
            start_date :  item?.start_date,
            category_id : item ?.category?.name,
          
            // expense_type : item?.category_id
        }));
    });

    const utilityData = transformedData;

    const utilityColumns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (text, record, index) => {
                return index + 1;
            },
        },

        {
            title: "Utility company",
            dataIndex: "company_id",
            key: "company",
            ...getColumnSearchProps("company_id"),
            sorter: (a, b) => (a.company_id || '').localeCompare(b.company_id || ''),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (amount) => {
                if (amount === null || amount === undefined ) {
                    return <p>{`${currency}0`}</p>;
                } else {
                    return <p>{`${currency}${amount}`}</p>;
                }
            },
            key: "amount",
            sorter: {
                compare: (a, b) => a.amount - b.amount,
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
                return (
                    <div className="action-container">
                        <Tooltip title="Edit">
                            <button
                                className="btn btnEdit me-2"
                                onClick={() => {

                                    navigate("/property_details", {

                                        state: {
                                            data: record,
                                            providerType: UtilityCategory,
                                            providerTypeName: UtilityCategoryName,
                                            propertyData: propertyDataState
                                        },
                                    });
                                }}
                            >
                                <FaEdit />
                            </button>
                        </Tooltip>

                        <Tooltip title="Delete">
                            <button
                                className="btn btnDelete me-2"
                                onClick={() => {
                                    setSelectedRecord(record.id);
                                    setIsModalOpen(true);
                                }}
                            >
                                <HiOutlineTrash />
                            </button>
                        </Tooltip>
                    </div>

                );
            },
        },
    ];


    const items = getProviderType ? (
        getProviderType?.flatMap((item) => {
            const providerTypes = Array.isArray(item.provider_type)
                ? item.provider_type
                : [item.provider_type];

            return providerTypes.map((property) => ({
                key: property.id,
                label: property.name,
                extra: (
                    <Button
                        className="btnFilled text-white"
                        onClick={() => {
                            manageExpense();
                              setUtilityCategory(property.id)
                            setUtiltityCategoryName(property.name);
                        }}
                    >
                        {"Manage Expense"}
                    </Button>
                ),
                children: (
                    isPropertyProvidersLoading ? (
                        <Spin size="large" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} />
                    ) : (
                        utilityData && utilityData.length > 0 && utilityData !== 'undefined' ? (
                            <Table
                                rowKey={record => record?.id}
                                bordered
                                dataSource={utilityData}
                                columns={utilityColumns}
                                showSorterTooltip={false}
                                pagination={
                                    utilityData?.data?.length > 0 && utilityData?.data?.length >= 5
                                        ? {
                                            total: 10,
                                            pageSize: pagesize,
                                            onChange: (page, pageSize) => {
                                                setPageSize(pageSize);
                                                setCurrent(page);
                                            },
                                            showSizeChanger: false,
                                            pageSizeOptions: ["5", "10", "20", "30", "50"],
                                        }
                                        : ""
                                }
                            />
                        ) : (
                            <p>No data found</p>

                        )
                    )

                ),
            }));
        })
    ) : (
        <p>No data found</p>
    );
    return (
        <div className="mainWrapper">
            <div className="card viewCard mb-4">
                <div className="card-body d-block">
                    <Title
                        title="Manage Utility Categories"
                        subtitle={
                            `${propertyData?.name_of_property} - ` +
                            `${propertyData?.address}, ` +
                            `${propertyData?.city_id ? propertyData?.city_id : propertyData?.city}, ` +
                            `${propertyData?.state_id? propertyData?.state_id : propertyData?.state}`
                        }
                    />
                    <div className="propertyDetail mt-2">
                        <span className="colLabel">Property Type: {propertyData?.property_type_name?.provider_name ??  propertyData?.property_type_name }</span>
        
                    </div>
                </div>
            </div>

            <div className="card tableCard">
                <div className="card-header d-flex align-items-center justify-content-between border-0">
                    <h5 className="card-title">Add Property Expense</h5>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="btnOutlined me-3"
                        onClick={showGroupSection}
                    >
                        Add Expense
                    </Button>
                </div>
                {showGroup && (
                    <div className="card-body p-3">
                        {getUtilityCategory?.data?.map((utility) => (
                            <div className="provider-content" key={utility.id}>
                                <div>{utility.name}</div>
                                <Button
                                    className="add-new"
                                    onClick={() => handleClick(utility)}
                                >
                                    {" "}
                                    {"Add New"}
                                </Button>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="card collapseCard">
                <div className="card-header">
                    <h5>Manage Property Expense</h5>
                </div>
                <div className="card-body">
                    {isProviderTypeLoading ? (
                        <Spin size="large" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} />
                    ) : getProviderType.length ? (
                      
                               
                                <Collapse
                                accordion
                                items={items}
                                onChange={(key) => {                           
                                    setUtilityCategory(key)
                                    manageExpense()                       
                                }}
                                showArrow={false}
                                expandIcon={false}
                            />
                             
                          
                     
                    ) : (
                        <p>No data Found</p>
                    )}
                </div>
            </div>
            <Modal
                className="deleteModal"
                title="Delete Expense"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={false}
            >
                <div className="iconRow">
                    <RxCrossCircled />
                </div>
                <div className="textRow">
                    <h3>Are you sure?</h3>
                    <p>Do you really want to delete this expense?</p>
                </div>
                <div className="buttonRow">
                    <Button className="btnOutlined me-2" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className="btnFilled text-white" onClick={() => handleOk()}>
                        Delete
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default ViewProperty;