import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import { Spin, Alert } from 'antd';
import { useGetRevenueGrowthComparisonMutation } from '../slices/apiSlice';

exporting(Highcharts);
exportData(Highcharts);

const ChartRevenue = ({data}) => {
  
    const[revenueGrowthData,setRevenueGrowthData] = useState([])
    const [revenueGrowthComparisonData,{isLoading,isError}] = useGetRevenueGrowthComparisonMutation();
    let tokenData = localStorage?.getItem('Token');

    const revenueGrowth = async () => {
        const res = await revenueGrowthComparisonData(data)
        setRevenueGrowthData(res.data)
    }

    useEffect(()=>{
        if(tokenData !== undefined) {
        revenueGrowth()
        }
    },[data.filter_type && data.filter_value,data.property_ids, tokenData])

    if (isLoading) {
        return <Spin style={{ display: "flex", alignItems: "center", justifyContent: "center" }} />;
    }

    if (isError) {
        return <Alert message="Error loading data" type="error" />;
    }

    const RevenueChart = (data) => {
        // Extract unique property names
        const propertyNames = [...new Set(data?.map(item => item.property_name))];

        // Extract unique months
        const months = [...new Set(data?.map(item => item.month))];

        // Create series data
        const series = months.map(month => ({
            name: month,
            data: propertyNames.map(property => {
                const propertyData = data.find(item => item.property_name === property && item.month === month);
                return propertyData ? propertyData.revenue : 0;
            })
        }));

        const options = {
            chart: {
                type: 'bar'
            },
            title: {
                text: 'Revenue Growth Comparison for Properties (Up to the last 3 Months)'
            },
            xAxis: {
                categories: propertyNames
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Revenue ($)'
                }
            },
            tooltip: {
                shared: true,
                valuePrefix: '$'
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: series
        };
        return options;
    };

    const options = RevenueChart(revenueGrowthData?.data);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default ChartRevenue;
