import React, { useState } from "react";
import { Form} from "antd";
import DynamicForm from "../../component/Form";
import { CiMail } from "react-icons/ci";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import images from "../../constant/images";
import { useForgotPasswordMutation } from "../../slices/loginRegisterSlice";
import { saveEmail } from "../../slices/authSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { Logo, SignInImage } = images;
  const fields = [
    {
      component: "input",
      type: "email",
      name: "email",
      placeholder: "Enter Email",
      prefix: <CiMail style={{ color: "#F47939", fontSize: "25px" }} />,
      rules: [
        {
          required: true,
          message: "Please input your email",
        },
      ],
    },
  ];

  const [userForgotPassword] =
    useForgotPasswordMutation();

  const onFinish = async (value) => {
    try {
      const response = await userForgotPassword(value);
      if (response.data) {
        setSuccessMessage(response.data.message);
        dispatch(saveEmail({ email: value }));
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      } else {
        setErrorMessage(response.error.data.message);
      }
    } catch (error) {

      setErrorMessage("User not found");
    }
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  return (
    <div className="authWrapper">
      <div className="authInner">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-6 whiteBg">
              <div className="formWrapper">
                <div className="logoWrap">
                  <Logo />
                </div>
                <div className="titleWrap">
                  <h2 className="authTitle">Forgot Password</h2>
                </div>
                <div className="formWrap">
                  {errorMessage && (
                    <div style={{ color: "red" }}>{errorMessage}</div>
                  )}
                  {successMessage && (
                    <div style={{ color: "#0FFF50" }}>{successMessage}</div>
                  )}
                  <DynamicForm
                    form={form}
                    fields={fields}
                    onFinish={onFinish}
                    buttonName={"Send Link"}
                    // loading={isLoading}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mobHide">
              <div className="imgWrapper">
                <SignInImage />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
